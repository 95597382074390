<template>
    <div class="video-gallery column">
        <template v-if="videos.length > 0">
            <div class="gallery-banner row">
                <img  :src="infoIcon" @click="showTeacherPage" /> 
                <span :key="video.id" >{{video.title || ''}}</span>
                <span aria-hidden="true" class="close-icon" @click="goBack">&times;</span>
            </div>
            <template v-if="videoSource">
                <video-player :key="video.id" :id="video.id" :url="videoSource"
                :thumbnail="video.thumbnail" :width="784" :height="441" />
                <button  @click="nextVideo" class="th-btn">{{$t('next')}}</button>
            </template>
        </template>
        <span v-else>{{$t('noContentToDisplayMessage')}}</span>
    </div>
</template>
<script>
import VideoPlayer  from '@/common/VideoPlayer.vue'
export default {
    name: 'VideoGallery',
    components:{
        VideoPlayer
    },
    data(){
        return {
            IndexOfVideoPlaying: 0,
        }
    },
    computed:{
        unit(){
            return this.$store.state.currentUnit
        },
        videos(){
            return this.$store.getters.videos
        }, 
        video(){
            return this.videos[this.IndexOfVideoPlaying]
        },
        videoSource(){
            return this.video.videoUrl
        },
        infoIcon(){
            return require('@/assets/icons/ic_info.svg')
        },
    }, 
    methods:{
        nextVideo(){
            this.IndexOfVideoPlaying === this.videos.length - 1
                ? this.IndexOfVideoPlaying = 0
                : this.IndexOfVideoPlaying++
        },
        goBack(){
            try {
                this.$router.go(-1)
            } catch(e) {
                this.$router.push({
                    name: 'Unit', 
                    params:{
                        ...this.$route.params
                    }
                })
            }
        }, 
        showTeacherPage(){
            if(this.$store.state.isPreviewModeActive)
                return 
            this.$router.push({ name: 'TeacherPageUnit', params: { 
                teacherPageId: this.unit.skillsId
              }, query:{
                title: this.unit.name
              }})
        }
    },
    mounted(){
        this.$appInsights.startTrackPage("Video Gallery");
        this.$store.commit("setAnalyzedManagementSection", "Video Gallery");
    },
    beforeDestroy(){
        this.$store.commit("setAnalyzedManagementSection", "");
        this.$appInsights.stopTrackPage("Video Gallery");
    }
}
</script>
<style lang="less" scoped>
.video-gallery{
    width: 1024px;
    margin: 0 auto;
    margin-top: 104px;
    min-height: 400px;
    span{
        margin: auto;
    }
    .gallery-banner{
        height: 66px;
        width: 100%;
        background-color: #EAEBFF;
        margin-bottom: 24px;
        img{
            margin-left: 42px;
        }

        span{
            align-self: center;
            margin-left: 14px;
            font-family: 'Roboto', sans-serif;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 2px;
            line-height: 16px;
            color: rgba(0,0,0,0.6);
            text-transform: uppercase;
        }
        .close-icon{
            margin-right: 45px;
            margin-left: auto;
            justify-self: flex-end;
            align-self: center;
            font-size: 2em;
            font-weight: 400;
            color: rgba(10, 21, 84, 0.6);
            &:hover{
                cursor: pointer;
            }
        }
    }
    .th-btn{
        margin-left: auto;
        margin-top: 21px;
        margin-right: 40px;
        height: 40px;
        width: 128px;
    }
    .video-player{
        margin: 0 auto;
    }
    position: relative;
}
</style>

